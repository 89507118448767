<template>
  <div class="about">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 d-flex justify-content-center mt-5 mt-lg-0">
          <CardImg  
            v-motion
            :initial="{
              y: 100,
              opacity: 0,
            }"
            :enter="{
              y: 0,
              opacity: 1,
              transition: {
                y: {
                  delay: 100,
                },
                opacity: {
                  duration: 1600,
                },
              },
            }"
          />         
        </div>
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <div class="col-9 d-flex flex-column">
            <h2 class="fs-5 mb-3 text-white">My name is <strong>Valentino Ghitti</strong> and i’m 24 years old.</h2>
            <div>
              <span>
                I started working from a young age, but during the pandemic, I discovered programming as my profession. I'm always seeking learning opportunities to grow in my career as a software developer.
              </span>
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row flex-sm-row justify-content-between mt-4">
            <div class="d-flex flex-column col-12 col-md-4">
              <div class="mb-1">
                <span class="text-muted row">
                  <i class="bi bi-geo-alt-fill col-1 icons"></i>
                  <span class="col-10">Córdoba, Argentina</span>
                </span>
              </div>
              <div class="mb-1">
                <span class="text-muted row">
                  <i class="bi bi-translate col-1 icons"></i>
                  <span class="col-10">English, Spanish</span>
                </span>
              </div>
              <div>
                <span class="text-muted row">
                  <i class="bi bi-envelope-fill col-1 icons"></i>
                  <span class="col-10">Valentinoghitti00@hotmail.com</span>
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a class="btn10 rounded d-flex justify-content-start" :href="pdfPath" download="cv-valentino-ghitti">Download CV</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardImg from '../components/CardImg.vue'
export default {
  name: 'About',
  components: {
    CardImg
  },
  computed: {
    pdfPath() {
      return process.env.BASE_URL + 'valentino-ghitti-2024.pdf';
    },
  },
}
</script>

<style>
a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: black;
}

.icons { 
  color: #FFB833;
}


.icons-about {
  display: flex;
  align-items: center;
  flex-flow: row;
  list-style:none;
  padding: 0;
}

.icons-about a {
  background-color:#eceeef;
  color:#000508;
  font-size:16px;
  display:inline-block;
  line-height:35px;
  width:35px;
  height:35px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  transition:all .2s linear;
}

.icons-about a:active, .icons-about a:focus, .icons-about a:hover {
  color:#fff;
  background-color: #fcb206;
}

.icons-about a.facebook:hover {
  background-color: #fcb206;
}
</style>
